// ReportHeader.js
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { UilUserCircle, UilPhone, UilMapMarker } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { InvoiceHeader } from './Style';

function ReportHeader({ reportInfo, companyInfo, facilitatorInfo }) {
  const { mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      mainContent: state.ChangeLayoutMode.mode,
    };
  });

  return (
    <StyledInvoiceHeader>
      <StyledTitle>{reportInfo.title}</StyledTitle>
      <ReportInfo>
        <h3>No: {reportInfo.reportNumber}</h3>
        <h3>Date: {reportInfo.date}</h3>
      </ReportInfo>
      <Row gutter={[16, 16]}>
        <Col sm={12} xs={12}>
          <AnalystInfo>
            <h3>Facilitator</h3>
            <p>
              <span>
                <UilUserCircle />
              </span>
              {facilitatorInfo.name}
            </p>
            <p>
              <span>
                <UilPhone />
              </span>
              {facilitatorInfo.phone}
            </p>
            <p>
              <span>
                <UilMapMarker />
              </span>
              {facilitatorInfo.address}
            </p>
          </AnalystInfo>
        </Col>
        <Col sm={12} xs={12}>
          <CompanyInfo>
            <address className="invoice-info">
              <figure className="company-logo">
                {mainContent === 'lightMode' ? (
                  <img src={companyInfo.logoLight} alt="Company Logo" />
                ) : (
                  <img src={companyInfo.logoDark} alt="Company Logo" />
                )}
              </figure>
              <div>
                {companyInfo.name} <br />
                {companyInfo.location} <br />
                {companyInfo.phone} <br />
              </div>
            </address>
          </CompanyInfo>
        </Col>
      </Row>
    </StyledInvoiceHeader>
  );
}

export default ReportHeader;

const StyledInvoiceHeader = styled(InvoiceHeader)`
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  margin-bottom: -20px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  @media (min-width: 769px) {
    margin-bottom: -20px;
  }
`;

const StyledTitle = styled.h1`
  color: #27ae60;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ReportInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  h3 {
    margin: 0;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    h3 {
      margin-bottom: 10px;
    }
  }
`;

const AnalystInfo = styled.div`
  p {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    svg {
      color: #27ae60;
    }
  }
  h3 {
    margin-bottom: 10px;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .invoice-info {
    text-align: right;
    .company-logo {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
    @media (max-width: 576px) {
      text-align: center;
      .company-logo {
        justify-content: center;
      }
    }
  }
`;
