// CorrectionPlan2.js
import React from 'react';
import { Table, Typography, Space } from 'antd';
import { EnvironmentOutlined, WeiboSquareOutlined, ToolOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Text } = Typography;

// Helper function to get the right icon component
const getIcon = (iconName, color) => {
  switch (iconName) {
    case 'EnvironmentOutlined':
      return <EnvironmentOutlined style={{ color }} />;
    case 'WeiboSquareOutlined':
      return <WeiboSquareOutlined style={{ color }} />;
    case 'ToolOutlined':
      return <ToolOutlined style={{ color }} />;
    default:
      return null;
  }
};

function SoilCorrectionPlan2({ data }) {
  const columns = [
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
      render: (text, record) => (
        <Space>
          {getIcon(record.icon, record.iconColor)}
          <Text strong>{text}</Text>
        </Space>
      ),
      width: '20%',
    },
    {
      title: 'Instructions',
      dataIndex: 'instructions',
      key: 'instructions',
      width: '30%',
    },
    {
      title: 'Best Option',
      dataIndex: 'bestOption',
      key: 'bestOption',
      width: '20%',
    },
    {
      title: 'First Alternative',
      dataIndex: 'firstAlternative',
      key: 'firstAlternative',
      width: '15%',
    },
    {
      title: 'Second Alternative',
      dataIndex: 'secondAlternative',
      key: 'secondAlternative',
      width: '15%',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      title={() => 'Soil Correction Plan'}
      pagination={false}
      bordered
      scroll={{ x: 800 }}
      rowClassName={(record) => (record.index % 2 === 0 ? 'even-row' : 'odd-row')}
      style={{
        tbody: {
          tr: {
            ':nth-child(even)': { backgroundColor: '#d4edda' },
            ':nth-child(odd)': { backgroundColor: '#f8d7da' },
          },
        },
      }}
    />
  );
}

SoilCorrectionPlan2.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SoilCorrectionPlan2;
