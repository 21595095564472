import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_KEY = 'AI--ss-zaSyDaGmWKa4JsXZ-HjGw7ISLn_3namBGewQe';

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  },
});

class DataService {
  static get(path = '', requiresApiKey = false) {
    const headers = requiresApiKey ? { ...authHeader(), 'x-api-key': API_KEY } : authHeader();

    return client({
      method: 'GET',
      url: path,
      headers,
    });
  }

  static post(path = '', data = {}, optionalHeader = {}, requiresApiKey = false) {
    const headers = requiresApiKey
      ? { ...authHeader(), ...optionalHeader, 'x-api-key': API_KEY }
      : { ...authHeader(), ...optionalHeader };

    return client({
      method: 'POST',
      url: path,
      data,
      headers,
    });
  }

  static patch(path = '', data = {}, requiresApiKey = false) {
    const headers = requiresApiKey ? { ...authHeader(), 'x-api-key': API_KEY } : authHeader();

    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers,
    });
  }

  static put(path = '', data = {}, requiresApiKey = false) {
    const headers = requiresApiKey ? { ...authHeader(), 'x-api-key': API_KEY } : authHeader();

    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers,
    });
  }

  static delete(path = '', requiresApiKey = false) {
    const headers = requiresApiKey ? { ...authHeader(), 'x-api-key': API_KEY } : authHeader();

    return client({
      method: 'DELETE',
      url: path,
      headers,
    });
  }
}

/**
 * Axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    }

    return Promise.reject(error);
  },
);

export { DataService };
