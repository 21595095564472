import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const TestRequestRegistration = lazy(() => import('../../container/test-requests/testRequestRegistration'));
const TestRequestList = lazy(() => import('../../container/test-requests/testRequestList'));
const TestRequestUpdate = lazy(() => import('../../container/test-requests/testRequestUpdate'));
const TestRequestDetail = lazy(() => import('../../container/test-requests/TestRequestDetail/testRequestDetails'));

function TestRequestRoute() {
  return (
    <Routes>
      <Route path="registration" element={<TestRequestRegistration />} />
      <Route path="list" element={<TestRequestList />} />
      <Route path="update/:id" element={<TestRequestUpdate />} />
      {/* <Route path="detail/:id" element={<TestRequestUpdate />} /> */}
      <Route path="detail/:id" element={<TestRequestDetail />} />
      <Route path="assign-agent" element={<TestRequestDetail />} />
    </Routes>
  );
}

export default TestRequestRoute;
