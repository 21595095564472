// OverviewDataList.js
import React from 'react';
import { Row, Col } from 'antd';
import propTypes from 'prop-types';
import { UilUserCircle, UilPhone, UilMapMarker } from '@iconscout/react-unicons';
import styled from 'styled-components';

import { OverviewDataStyleWrap } from './Style2';
import OverviewCard from './OverviewCard';

const OverviewDataList = React.memo(({ farmerInfo, farmData, soilData, column }) => {
  // Combine farm and soil data
  const allData = [...farmData, ...soilData];

  return (
    <FarmerDetailsBox>
      <OverviewDataStyleWrap>
        <Farmer>
          <div className="">
            <center>
              <h3>Farmer information</h3>
            </center>
            <div className="general-info">
              <p>
                <span>
                  <UilUserCircle />
                </span>
                {farmerInfo.name}
              </p>
              <p>
                <span>
                  <UilPhone />
                </span>
                {farmerInfo.phone}
              </p>
              <p>
                <span>
                  <UilMapMarker />
                </span>
                {farmerInfo.address}
              </p>
            </div>
          </div>
        </Farmer>
        <Row gutter={25}>
          {allData.map((item, i) => {
            return (
              <Col xxl={column === '2' ? null : 6} md={6} xs={12} key={i}>
                <OverviewCard data={item} contentFirst />
              </Col>
            );
          })}
        </Row>
      </OverviewDataStyleWrap>
    </FarmerDetailsBox>
  );
});

OverviewDataList.propTypes = {
  column: propTypes.string,
  farmerInfo: propTypes.object.isRequired,
  farmData: propTypes.array.isRequired,
  soilData: propTypes.array.isRequired,
};

OverviewDataList.defaultProps = {
  column: '2',
};

export default OverviewDataList;

const FarmerDetailsBox = styled.div`
  background: ${({ theme }) => theme[theme.mainContent]['main-background-light']};
  padding: 10px;
`;

const Farmer = styled.div`
  h1 {
    font-size: 20px;
    text-align: center;
  }
  .general-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
    p {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
`;
