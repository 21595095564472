import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Report = lazy(() => import('../../container/report/Report'));

function SubscriptionRoute() {
  return (
    <Routes>
      <Route path=":soilTestId" element={<Report />} />
    </Routes>
  );
}
export default SubscriptionRoute;
