/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useRef } from 'react';
import { Col, Row, Spin, notification } from 'antd';
import styled from 'styled-components';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import ReportHeader from './ReportHeader';
import { Recommendation } from './Recommendation';
import OverviewDataList from './OverviewDataList';
// import SoilCorrectionPlan1 from './CorrectionPlan1';
import SoilCorrectionPlan2 from './CorrectionPlan2';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { DataService } from '../../config/dataService/dataService';

function Report() {
  const { soilTestId } = useParams();
  const [loading, setLoading] = useState(true);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [farmerDetails, setFarmerDetails] = useState(null);
  const [agentDetails, setAgentDetails] = useState(null);
  const targetRef = useRef();
  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: 'topRight',
    });
  };
  // Fetch soil test data and related user details
  useEffect(() => {
    const fetchSoilTestData = async () => {
      setLoading(true);
      try {
        // Fetch soil test results
        const soilTestResponse = await DataService.get(`soil-test-results/${soilTestId}`);
        const soilTestData = soilTestResponse.data;

        // Fetch farmer details
        const farmerResponse = await DataService.get(`users/${soilTestData.farmerNationalId}`);
        setFarmerDetails(farmerResponse.data);

        // Fetch agent details
        const agentResponse = await DataService.get(`users/${soilTestData.agentNationalId}`);
        setAgentDetails(agentResponse.data);

        setReportData(soilTestData);
      } catch (error) {
        console.error('Error fetching report data:', error);
        openNotification('error', 'Data Error', 'Failed to load report data');
      } finally {
        setLoading(false);
      }
    };

    if (soilTestId) {
      fetchSoilTestData();
    }
  }, [soilTestId]);

  const downloadPDF = async () => {
    setLoading(true);
    setIsGeneratingPDF(true);
    openNotification('info', 'Download Started', 'Generating your PDF report...');
    const element = targetRef.current;
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const padding = 25;

    try {
      const canvas = await html2canvas(element, { scrollY: -window.scrollY });
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const totalPages = Math.ceil(imgHeight / (pdfHeight - padding * 2));

      let yOffset = 0;

      for (let page = 0; page < totalPages; page += 1) {
        if (page > 0) {
          pdf.addPage();
          yOffset = page * (pdfHeight - padding * 2);
        }

        pdf.addImage(
          imgData,
          'PNG',
          padding,
          padding - yOffset,
          pdfWidth - padding * 2,
          (pdfWidth * imgProps.height) / imgProps.width,
        );
      }

      pdf.save('soil-test-report.pdf');
      openNotification('success', 'Download Complete', 'Your PDF report has been downloaded.');
    } catch (error) {
      console.error('PDF generation error:', error);
      openNotification('error', 'Download Failed', 'There was an error generating your PDF report.');
    } finally {
      setLoading(false);
      setIsGeneratingPDF(false);
    }
  };

  // Format data for components
  const prepareFormattedData = () => {
    if (!reportData || !farmerDetails || !agentDetails) return null;

    const currentDate = new Date().toLocaleDateString('en-GB');

    return {
      reportInfo: {
        reportNumber: reportData._id,
        date: new Date(reportData.createdAt).toLocaleDateString('en-GB') || currentDate,
        title: 'SOIL TEST REPORT',
      },
      companyInfo: {
        name: 'Shamba Solutions',
        location: 'Nairobi',
        phone: '07144444444',
        logoLight: require('../../static/img/logo_dark.svg').default,
        logoDark: require('../../static/img/logo_white.svg').default,
      },
      facilitatorInfo: {
        name: agentDetails.userEmail || '',
        phone: agentDetails.userPhoneNumber || '',
        address: `${agentDetails.userCounty || ''}, ${agentDetails.userSubCounty || ''}`,
      },
      farmerInfo: {
        name: farmerDetails.userEmail || farmerDetails.userNationalId,
        phone: farmerDetails.userPhoneNumber || '',
        address: `${farmerDetails.userCounty || ''}, ${farmerDetails.userSubCounty || ''}`,
      },
      farmData: [
        {
          id: 1,
          type: 'primary',
          icon: 'land-icon.svg',
          label: 'Field Size',
          title: '2 ha',
        },
        {
          id: 2,
          type: 'info',
          icon: 'business.svg',
          label: 'Target Yield',
          title: '20,000kg',
        },
        {
          id: 3,
          type: 'secondary',
          icon: 'crop.svg',
          label: 'Crop Planned',
          title: 'Maize',
        },
        {
          id: 4,
          type: 'warning',
          icon: 'soil.svg',
          label: 'Soil texture',
          title: 'Loamy',
        },
      ],
      soilData: [
        {
          id: 5,
          type: 'primary',
          icon: 'potassium.svg',
          label: 'Potassium (K)ppm',
          title: reportData.report.soilData.potassium || '0.0',
        },
        {
          id: 6,
          type: 'primary',
          icon: 'nitrogen-icon.svg',
          label: 'Nitrogen (N)ppm',
          title: reportData.report.soilData.nitrogen || '0.0',
        },
        {
          id: 7,
          type: 'primary',
          icon: 'phosphorus.svg',
          label: 'Phosphorus (P)ppm',
          title: reportData.report.soilData.phosphorus || '0.0',
        },
        {
          id: 8,
          type: 'primary',
          icon: 'water-drop.svg',
          label: 'pH',
          title: reportData.report.soilData.ph || '0.0',
        },
      ],
      soilCorrectionData: [
        {
          key: '1',
          activity: 'Before Planting',
          instructions:
            reportData.report.fertilizerApplicationPlan.soilCorrection.organicMatterApplication ||
            'No specific recommendation',
          bestOption: reportData.report.fertilizerApplicationPlan.soilCorrection.organicMatterApplication,
          firstAlternative: 'Animal manure',
          secondAlternative: 'Commercial organic fertilizer',
          icon: 'EnvironmentOutlined',
          iconColor: '#2ecc71',
        },
        {
          key: '2',
          activity: 'At Planting',
          instructions:
            reportData.report.fertilizerApplicationPlan.bestOption.firstApplication.fertilizer ||
            'No specific recommendation',
          bestOption: reportData.report.fertilizerApplicationPlan.bestOption.firstApplication.fertilizer,
          firstAlternative: reportData.report.fertilizerApplicationPlan.secondOption.firstApplication.fertilizer,
          secondAlternative: reportData.report.fertilizerApplicationPlan.thirdOption.firstApplication.fertilizer,
          icon: 'WeiboSquareOutlined',
          iconColor: '#3498db',
        },
        {
          key: '3',
          activity: '6 weeks after',
          instructions:
            reportData.report.fertilizerApplicationPlan.bestOption.secondApplication.fertilizer ||
            'No specific recommendation',
          bestOption: reportData.report.fertilizerApplicationPlan.bestOption.secondApplication.fertilizer,
          firstAlternative: reportData.report.fertilizerApplicationPlan.secondOption.secondApplication.fertilizer,
          secondAlternative: reportData.report.fertilizerApplicationPlan.thirdOption.secondApplication.fertilizer,
          icon: 'ToolOutlined',
          iconColor: '#f39c12',
        },
      ],
      recommendations: reportData.report.recommendations || '',
    };
  };

  const formattedData = prepareFormattedData();

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
        <p>Loading soil test report...</p>
      </div>
    );
  }

  return (
    <div className="invoice-area">
      <PageHeader className="ninjadash-page-header-main" title="Soil Test Report" />
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <Spin spinning={loading}>
                <StyledButton onClick={downloadPDF} disabled={loading || !formattedData}>
                  Download as PDF
                </StyledButton>
              </Spin>
              {formattedData && (
                <div id="print-invoice-content" ref={targetRef}>
                  <PrintingWrapper isGeneratingPDF={isGeneratingPDF}>
                    <ReportHeader
                      reportInfo={formattedData.reportInfo}
                      companyInfo={formattedData.companyInfo}
                      facilitatorInfo={formattedData.facilitatorInfo}
                    />
                    <OverviewDataList
                      farmerInfo={formattedData.farmerInfo}
                      farmData={formattedData.farmData}
                      soilData={formattedData.soilData}
                    />
                    {/* <SoilCorrectionPlan1 data={formattedData.soilCorrectionData} /> */}
                    <SoilCorrectionPlan2 data={formattedData.soilCorrectionData} />
                    <div style={{ marginTop: '200px' }} />
                    <Recommendation recommendations={formattedData.recommendations} />
                    <center>
                      <p>
                        <strong>Shamba solutions original report-{new Date().getFullYear()}</strong>
                      </p>
                    </center>
                  </PrintingWrapper>
                </div>
              )}
            </Cards>
          </Col>
        </Row>
      </Main>
    </div>
  );
}

export default Report;

const PrintingWrapper = styled.div`
  padding: 10px 20px;
  ${(props) =>
    props.isGeneratingPDF &&
    `
    .recommandation-margin {
      margin-top: 200px;
    }
  `}
`;

const StyledButton = styled.button`
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;

  &:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }

  &:active {
    background-color: #3e8e41;
    transform: scale(0.95);
  }

  &:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
`;
