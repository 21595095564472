import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AgentList = lazy(() => import('../../container/agent/AgentList'));
const AgentSoilTests = lazy(() => import('../../container/agent/AgentSoilTests'));
const AgentUpdate = lazy(() => import('../../container/agent/AgentUpdate'));
const AgentDetail = lazy(() => import('../../container/agent/AgentDetail/AgentDetails'));

function AgentRoute() {
  return (
    <Routes>
      <Route path="list" element={<AgentList />} />
      <Route path="update/:id" element={<AgentUpdate />} />
      {/* <Route path="detail/:id" element={<AgentUpdate />} /> */}
      <Route path="detail/:id" element={<AgentDetail />} />
      <Route path="soil-tests/:agentNationalId" element={<AgentSoilTests />} />
    </Routes>
  );
}

export default AgentRoute;
