import React from 'react';
import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const { Title } = Typography;

export function Recommendation({ recommendations }) {
  return (
    <Card title="" style={{ width: '100%' }} className="recommandation-margin">
      <Title level={3}>Soil Testing Report Recommendations</Title>
      {/* Rendered recommendations as markdown */}
      <div className="recommendation-content">
        <ReactMarkdown>{recommendations}</ReactMarkdown>
      </div>
    </Card>
  );
}

Recommendation.propTypes = {
  recommendations: PropTypes.string.isRequired,
};
